<template>
    <div class="analytics">
        <GuestsBoardAnalytics />
        <span class="pb-16 d-block" />
    </div>
</template>

<script>
    
    import GuestsBoardAnalytics from './GuestsBoardAnalytics.vue'

    export default {

        components: {
            GuestsBoardAnalytics,
        },
    }
</script>

<style lang="stylus" scoped>
    .analytics
        height 100%
        padding-top 10px !important
        .overview
            max-width 400px
        .text
            padding 0
        .v-card
            cursor default
        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    white-space nowrap
                    padding 0 8px

            .v-data-table__wrapper > table > tbody > tr
                &:hover
                    background-color transparent !important
                td
                    white-space nowrap
                    cursor default
                    .text
                        padding 0 8px

                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px
                        height 22px
                    td.tooltip-text
                        padding-left 0
                    td
                        padding-left 0

        .v-tooltip__content
            background #222
            color #fff
            border-radius 6px
            padding 5px 10px 4px

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px
                cursor default
                padding 0 6px

        .v-chip.v-size--x-small
            height 18px

        b
            font-weight 500

        .info-card
            cursor pointer

        .v-list-item__content
            padding 8px 0
            overflow initial

        .no-data
            width 100%
            display block
            text-align center
            color #999
            font-size 13px
            height 30px
            line-height 30px

        .guest-error
            color #F44336

        .guest-selectable
            font-weight 400
            color #0D47A1
            cursor pointer
            &:hover
                text-decoration: underline
</style>
