<template>
	<div style="max-width: 1800px" class="PanelProceeds mx-auto">
		<v-card class="ma-3 elevation-6">
			<v-toolbar
				class="mb-2"
				height="50"
				color="indigo darken-6 elevation-3"
				dark
				flat
			>
				<v-toolbar-title class="overline">Аналитика клиентов</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn icon @click="getChartsGuestAnalytic"
					><v-icon>mdi-refresh</v-icon></v-btn
				>
			</v-toolbar>

			<v-row class="pl-5 pr-5 pt-5">
				<v-col>
					<v-card class="ma-1">
						<v-card-title class="pl-6 pr-5 pb-8 pt-5">
							<h1 class="font-weight-light">Параметры</h1>
						</v-card-title>
						<v-row class="pl-5 pr-5">
							<v-col cols="12" md="4" v-show="$store.state.user.role == 1">
								<p class="pa-0 ma-0 pl-2">Точка</p>
								<v-autocomplete
									no-data-text="Не найдена точка"
									:items="point_list"
									v-model="point_select"
									item-text="name"
									item-value="id"
									class="ma-1"
									persistent-hint
									return-object
									single-line
									solo
									hide-details
									@change="setPoint"
								></v-autocomplete>
							</v-col>
							<v-col cols="12" md="4">
								<p class="pa-0 ma-0 pl-2">Период</p>
								<v-select
									:items="time_preset"
									v-model="time_preset_select"
									@change="setTimePreset"
									item-text="name"
									item-value="time"
									label="Время"
									solo
									hide-details
									class="ma-1"
								></v-select>
							</v-col>
							<v-col cols="12" md="4">
								<p class="pa-0 ma-0 pl-2">Глубина времени</p>
								<v-select
									:items="deep_preset"
									item-disabled="customDisabled"
									v-model="deep_select"
									item-text="name"
									item-value="deep"
									label="Глубина"
									solo
									hide-details
									class="ma-1"
									@change="getChartsGuestAnalytic"
								></v-select
							></v-col>
						</v-row>
						<v-row class="pl-5 pr-5 mt-0" v-show="show_calendar">
							<v-col>
								<v-date-picker
									range
									v-show="show_calendar"
									v-model="dates_calendar"
									@change="setTime"
									locale="ru-ru"
									selected-items-text="Выбран период"
									:first-day-of-week="1"
									:elevation="3"
									full-width
								>
								</v-date-picker
							></v-col>
						</v-row>
						<v-row class="pl-5 pr-5 pb-3">
							<v-col>
								<p class="ma-1 mt-0">
									Период: c <b>{{ time_start || "Не задано" }}</b> по
									<b>{{ time_end || "Не задано" }}</b>
								</p>
								<p class="ma-1 mt-0">
									Количество дней:
									<b>{{ count_day }}</b>
								</p>
							</v-col>
						</v-row>
					</v-card>
				</v-col>
			</v-row>
			
			<v-row class="pl-8 pr-8 pt-3 card-center d-flex justify-center">
				<v-card class="ma-1" width="300" height="430">
					<v-toolbar class="mb-2" height="50" color="cyan darken-1" dark flat>
						<v-toolbar-title class="overline">Выручка</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
							</template>
							<span>Оплата за заказы от постоянных и новых клиентов. ( Постоянным считается клиент, который уже хотя бы раз делал заказ у точки в любой период времени ) </span>
						</v-tooltip>
					</v-toolbar>
						
					<div class="pa-5">
						<div class="pie-wrapper">
							<Pie :chartData="chartPieProceeds" :options="pie_options" class="piechartZ card-pie" />
							<div class="center-wrapper">
								<v-icon class="display-4">mdi-hand-coin</v-icon>
							</div>
						</div>
					
						<v-row class="pt-10 pl-5 pr-5">
							<v-col cols="2" class="pa-0">
								<v-icon color="rgba(76, 175, 80, 1)">mdi-cash-multiple</v-icon>
							</v-col>
							<v-col cols="5" class="pa-0">Постоянные</v-col>
							<v-col cols="5" class="pa-0 text-right">{{
								Intl.NumberFormat("ru-RU").format(Number(proceeds_old))
								}}
								₽</v-col>
						</v-row>
						<v-row class="pl-5 pr-5">
							<v-col cols="2" class="pa-0">
								<v-icon color="rgba(255, 152, 0, 1)">mdi-cash-multiple</v-icon>
							</v-col>
							<v-col cols="5" class="pa-0">Новые</v-col>
							<v-col cols="5" class="pa-0 text-right">{{
								Intl.NumberFormat("ru-RU").format(Number(proceeds_new))
								}}
								₽</v-col>
						</v-row>
					
					</div>
				</v-card>

				<v-card class="ma-1" width="300" height="430">
					<v-toolbar class="mb-2" height="50" color="cyan darken-1" dark flat>
						<v-toolbar-title class="overline">Заказы по гендеру</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
							</template>
							<span>Кол-во заказов сделанных мужчинами и женщинами за выбранный период</span>
						</v-tooltip>
					</v-toolbar>
					<div class="pa-5">
						<div class="pie-wrapper">
							<Pie
								:chartData="chartPieCountGender"
								:options="pie_options"
								class="piechartZ card-pie"
							/>
							<div class="center-wrapper">
								<v-icon class="display-4">mdi-human-male-female</v-icon>
							</div>
						</div>
						<v-row class="pt-10 pl-5 pr-5">
							<v-col cols="2" class="pa-0"
								><v-icon color="rgba(33, 150, 243, 1)"
									>mdi-face-man</v-icon
								></v-col
							>
							<v-col cols="5" class="pa-0">Мужчины</v-col>
							<v-col cols="5" class="pa-0 text-right">{{
								Intl.NumberFormat("ru-RU").format(Number(count_male))
							}}</v-col>
						</v-row>
						<v-row class="pl-5 pr-5">
							<v-col cols="2" class="pa-0"
								><v-icon color="rgba(233, 30, 99, 1)"
									>mdi-face-woman</v-icon
								></v-col
							>
							<v-col cols="5" class="pa-0">Женщины</v-col>
							<v-col cols="5" class="pa-0 text-right">{{
								Intl.NumberFormat("ru-RU").format(Number(count_female))
							}}</v-col>
						</v-row>
					</div>
				</v-card>

				<v-card class="ma-1" width="300" height="430">
					<v-toolbar class="mb-2" height="50" color="cyan darken-1" dark flat>
						<v-toolbar-title class="overline">Заказы по активности</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
							</template>
							<span>Кол-во заказов оформленных постоянными и новыми клиентами. ( Постоянным считается клиент, который уже хотя бы раз делал заказ у точки в любой период времени )</span>
						</v-tooltip>
					</v-toolbar>
					<div class="pa-5">
						<div class="pie-wrapper">
							<Pie
								:chartData="chartPieNewOld"
								:options="pie_options"
								class="piechartZ card-pie"
							/>
							<div class="center-wrapper">
								<v-icon class="display-4">mdi-account-group</v-icon>
							</div>
						</div>
						<v-row class="pt-10 pl-5 pr-5">
							<v-col cols="2" class="pa-0"
								><v-icon color="rgba(76, 175, 80, 1)"
									>mdi-account-heart</v-icon
								></v-col
							>
							<v-col cols="5" class="pa-0">Постоянные</v-col>
							<v-col cols="5" class="pa-0 text-right">{{
								Intl.NumberFormat("ru-RU").format(Number(count_old))
							}}</v-col>
						</v-row>
						<v-row class="pl-5 pr-5">
							<v-col cols="2" class="pa-0"
								><v-icon color="rgba(255, 152, 0, 1)"
									>mdi-account</v-icon
								></v-col
							>
							<v-col cols="5" class="pa-0">Новые</v-col>
							<v-col cols="5" class="pa-0 text-right">{{
								Intl.NumberFormat("ru-RU").format(Number(count_new))
							}}</v-col>
						</v-row>
					</div>
				</v-card>

				<v-card class="ma-1" width="300" height="430">
					<v-toolbar class="mb-2" height="50" color="cyan darken-1" dark flat>
						<v-toolbar-title class="overline">Клиенты по кол-ву заказов</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
							</template>
							<span>Кол-во клиентов, оформивших один, два, три и более заказов за выбранный период</span>
						</v-tooltip>
					</v-toolbar>
					<div class="pa-5">
						<div class="pie-wrapper">
							<Pie
								:chartData="chartPieCounts"
								:options="pie_options"
								class="piechartZ card-pie"
							/>
							<div class="center-wrapper">
								<v-icon class="display-4">mdi-dolly</v-icon>
							</div>
						</div>
						<v-row class="pt-10 pl-5 pr-5">
							<v-col cols="2" class="pa-0"
								><v-icon color="#2196f3">mdi-numeric-1-circle</v-icon></v-col
							>
							<v-col cols="5" class="pa-0">Единичные</v-col>
							<v-col cols="5" class="pa-0 text-right">{{
								Intl.NumberFormat("ru-RU").format(Number(count_ones))
							}}</v-col>
						</v-row>
						<v-row class="pl-5 pr-5">
							<v-col cols="2" class="pa-0"
								><v-icon color="#dc3546">mdi-numeric-2-circle</v-icon></v-col
							>
							<v-col cols="5" class="pa-0">Два</v-col>
							<v-col cols="5" class="pa-0 text-right">{{
								Intl.NumberFormat("ru-RU").format(Number(count_twos))
							}}</v-col>
						</v-row>
						<v-row class="pl-5 pr-5">
							<v-col cols="2" class="pa-0"
								><v-icon color="#ff9800">mdi-alpha-m-circle</v-icon></v-col
							>
							<v-col cols="5" class="pa-0">Три и более</v-col>
							<v-col cols="5" class="pa-0 text-right">{{
								Intl.NumberFormat("ru-RU").format(Number(count_more))
							}}</v-col>
						</v-row>
					</div>
				</v-card>
			</v-row>
			<v-row class="pl-5 pr-5">
				<v-col>
					<v-card class="ma-1">
						<v-toolbar height="50" color="red" dark flat justify-center>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon class="mr-2" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
								</template>
								<span>Оплата за заказы от постоянных и новых клиентов. ( Постоянным считается клиент, который уже хотя бы раз делал
									заказ у точки в любой период времени ) </span>
							</v-tooltip>
							<v-toolbar-title class="overline"
								>Выручка
							</v-toolbar-title>
							<v-spacer></v-spacer>
							<v-switch
								class="ma-0 pa-0"
								v-model="switchProceed"
								:label="`Объединить`"
								hide-details
							></v-switch>
						</v-toolbar>
						<div v-show="switchProceed">
							<LineChart
								:chartData="chartProceeds"
								style="height: 400px"
								:options="optionsLine"
								class="pa-5"
							/>
						</div>
						<div v-show="!switchProceed">
							<v-row>
								<v-col>
									<LineChart
										:chartData="chartProceedsNew"
										style="height: 400px"
										:options="optionsLine"
										class="pa-5"
									/>
								</v-col>
								<v-col>
									<LineChart
										:chartData="chartProceedsOld"
										style="height: 400px"
										:options="optionsLine"
										class="pa-5"
									/>
								</v-col>
							</v-row>
						</div>
					</v-card>
				</v-col>
			</v-row>
			<v-row class="pl-5 pr-5">
				<v-col>
					<v-card class="ma-1">
						<v-toolbar height="50" color="purple" dark flat>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon class="mr-2" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
								</template>
								<span>Кол-во заказов сделанных мужчинами и женщинами за выбранный период</span>
							</v-tooltip>
							<v-toolbar-title class="overline"
								>Количество мужчин и женщин совершивших заказ</v-toolbar-title
							>
							<v-spacer></v-spacer>
							<v-switch
								class="ma-0 pa-0"
								v-model="switchChartGender"
								:label="`Объединить`"
								hide-details
							></v-switch>
						</v-toolbar>

						<div v-show="switchChartGender">
							<LineChart
								:chartData="chartSex"
								:options="optionsLine"
								style="height: 400px"
								class="pa-5"
							/>
						</div>
						<div v-show="!switchChartGender">
							<v-row>
								<v-col>
									<LineChart
										:chartData="chartGenderMale"
										:options="optionsLine"
										style="height: 400px"
										class="pa-5"
									/>
								</v-col>
								<v-col>
									<LineChart
										:chartData="chartGenderFemale"
										:options="optionsLine"
										style="height: 400px"
										class="pa-5"
									/>
								</v-col>
							</v-row>
						</div> </v-card
				></v-col>
			</v-row>
			<v-row class="pl-5 pr-5">
				<v-col>
					<v-card class="ma-1">

						<v-toolbar height="50" color="#ff9800" dark flat>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon class="mr-2" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
								</template>
								<span>Кол-во заказов оформленных постоянными и новыми клиентами. ( Постоянным считается клиент, который уже хотя бы
									раз делал заказ у точки в любой период времени )</span>
							</v-tooltip>
							<v-toolbar-title class="overline"
								>Заказы от новых и постоянных клиентов</v-toolbar-title
							>
							<v-spacer></v-spacer>
							<v-switch
								class="ma-0 pa-0"
								v-model="switchChartCount"
								:label="`Объединить`"
								hide-details
							></v-switch>
						</v-toolbar>
						<div v-show="switchChartCount">
							<LineChart
								:chartData="chartCount"
								style="height: 400px"
								:options="optionsLine"
								class="pa-5"
							/>
						</div>
						<div v-show="!switchChartCount">
							<v-row>
								<v-col>
									<LineChart
										:chartData="chartCountNew"
										:options="optionsLine"
										style="height: 400px"
										class="pa-5"
									/>
								</v-col>
								<v-col>
									<LineChart
										:chartData="chartCountOld"
										:options="optionsLine"
										style="height: 400px"
										class="pa-5"
									/>
								</v-col>
							</v-row>
						</div>
					</v-card>
				</v-col>
			</v-row>
			<v-row class="pl-5 pr-5 pb-2">
				<v-col>
					<v-card class="ma-1">
						<v-toolbar height="50" color="#2196f3" dark flat>
							<v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-icon class="mr-2" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
								</template>
								<span>Кол-во клиентов, оформивших один, два, три и более заказов за выбранный период</span>
							</v-tooltip>
							<v-toolbar-title class="overline"
								>Частоты заказов</v-toolbar-title
							>
						</v-toolbar>
						<Bar
							:chartData="chartFrequency"
							:options="optionsBar"
							class="pa-5"
						/>
					</v-card>
				</v-col>
			</v-row>
		</v-card>
	</div>
</template>

<script>
	import LineChart from "../LineChart.js";
	import Bar from "../Bar.js";
	import Pie from "../Pie.js";
	import DateTime from "../../../mixins/DateTime.js";
	

	export default {
		mixins: [DateTime],
		components: {
			LineChart,
			Bar,
			Pie,
		},
		data() {
			return {
				getdate: [],

				loading_show: false,
				show_calendar: false,
				switchProceed: false,
				switchChartGender: true,
				switchChartCount: false,

				point_id: 0,
				point_list: [],
				point_select: {},
				time_preset_select: "today",
				time_preset: [
					{ name: "Сегодня", time: "today" },
					{ name: "Вчера", time: "yesterday" },
					{ name: "Позавчера", time: "two days ago" },
					{ name: "7 дней", time: "Last week" },
					{ name: "30 дней", time: "Last month" },
					{ name: "90 дней", time: "Last 3 months" },
					{ name: "365 дней", time: "Last 12 months" },
					{ name: "Произвольный период", time: "Calendar" }
				],
				deep_select: "hour",
				deep_preset: [
					{ name: "месяц", deep: "month", customDisabled: false },
					{ name: "день", deep: "day", customDisabled: false },
					{ name: "час", deep: "hour", customDisabled: false }
				],
				dates_calendar: [],
				time_start: "2022-01-01",
				time_end: "2022-01-01",
				count_day: 0,

				count_male: 0,
				count_female: 0,
				count_new: 0,
				count_old: 0,
				count_ones: 0,
				count_twos: 0,
				count_more: 0,
				proceeds_new: 0,
				proceeds_old: 0,
				delta_old_new: 0,
				sum_old_new: 0,

				time_list: [],
				count_new_list: [],
				count_old_list: [],
				count_ones_list: [],
				count_twos_list: [],
				count_more_list: [],
				count_male_list: [],
				count_female_list: [],
				proceeds_new_list: [],
				proceeds_old_list: [],
				chartCountNew: {},
				chartCountOld: {},
				chartCount: {},
				chartFrequency: {},
				chartSex: {},
				chartGenderMale: {},
				chartGenderFemale: {},
				chartProceedsNew: {},
				chartProceedsOld: {},
				chartProceeds: {},
				chartPieCountGender: {},
				chartPieProceeds: {},
				chartPieCounts: {},
				chartPieNewOld: {},

				optionsLine: {
					tooltips: {
						callbacks: {
							title: function(tooltipItem, data) {
								const beautiful_hour_labels = hour => {
									let hour_str = ("0" + `${hour}`).slice(-2);
									return hour_str + ":00";
								};

								let multiStringText = [];
								let deep = data.datasets[0].deep_chart;

								let t = data.datasets[0].timeCA[tooltipItem[0].index];
								let y = t.getFullYear();
								let d = t.getDate();
								let h = t.getHours();

								let monthString = new Intl.DateTimeFormat("ru-RU", {
									month: "short"
								}).format(t);
								let dayString = new Intl.DateTimeFormat("ru-RU", {
									weekday: "short"
								}).format(t);

								if (deep == "month") {
									multiStringText.push(`Дата: ${monthString} ${y}`);
								}
								if (deep == "day") {
									multiStringText.push(`Дата: ${d} ${monthString} ${y} (${dayString})`);
								}
								if (deep == "hour") {
									multiStringText.push(`Дата: ${d} ${monthString} ${y} (${dayString})`);
									multiStringText.push(`Время: ${beautiful_hour_labels(h)}`);
								}
								return multiStringText;
							},
							label: function(tooltipItem, data) {
								const toCashStr = m => {
									return new Intl.NumberFormat("ru-RU").format(Number(m));
								};

								let multiStringText = [];
								let chartName = data.datasets[0].name_chart_line;
								multiStringText.push(``);

								if (chartName == "chartProceeds") {
									let proceeds_new = data.datasets[0].data[tooltipItem.index];
									let proceeds_old = data.datasets[1].data[tooltipItem.index];
									multiStringText.push(
										`🟧 Выручка от новых клиентов: ${toCashStr(proceeds_new)} ₽`
									);
									multiStringText.push(
										`🟩 Выручка от постоянных клиентов: ${toCashStr(
											proceeds_old
										)} ₽`
									);
								}

								if (chartName == "chartProceedsNew") {
									let proceeds_new = data.datasets[0].data[tooltipItem.index];
									multiStringText.push(
										`🟧 Выручка от новых клиентов: ${toCashStr(proceeds_new)} ₽`
									);
								}

								if (chartName == "chartProceedsOld") {
									let proceeds_old = data.datasets[0].data[tooltipItem.index];
									multiStringText.push(
										`🟩 Выручка от постоянных клиентов: ${toCashStr(
											proceeds_old
										)} ₽`
									);
								}

								if (chartName == "chartSex") {
									let count_male = data.datasets[0].data[tooltipItem.index];
									let count_female = data.datasets[1].data[tooltipItem.index];
									multiStringText.push(`🟦 Мужчин :${count_male} чел.`);
									multiStringText.push(`🟥 Женщин: ${count_female} чел`);
								}

								if (chartName == "chartGenderMale") {
									let count_male = data.datasets[0].data[tooltipItem.index];
									multiStringText.push(`🟦 Мужчин :${count_male} чел.`);
								}

								if (chartName == "chartGenderFemale") {
									let count_female = data.datasets[0].data[tooltipItem.index];
									multiStringText.push(`🟥 Женщин: ${count_female} чел`);
								}

								if (chartName == "chartCount") {
									let count_new = data.datasets[1].data[tooltipItem.index];
									let count_old = data.datasets[0].data[tooltipItem.index];
									multiStringText.push(
										`🟨 Заказы от новых клинтов: ${count_new} шт.`
									);
									multiStringText.push(
										`🟩 Заказы от постоянных клиентов: ${count_old} шт.`
									);
								}

								if (chartName == "chartCountNew") {
									let count_new = data.datasets[0].data[tooltipItem.index];
									multiStringText.push(
										`🟨 Заказы от новых клинтов: ${count_new} шт.`
									);
								}

								if (chartName == "chartCountOld") {
									let count_old = data.datasets[0].data[tooltipItem.index];
									multiStringText.push(
										`🟩 Заказы от постоянных клиентов: ${count_old} шт.`
									);
								}

								if (chartName == "chartFrequency") {
									let count_ones = data.datasets[0].data[tooltipItem.index];
									let count_twos = data.datasets[1].data[tooltipItem.index];
									let count_more = data.datasets[2].data[tooltipItem.index];

									multiStringText.push(
										`🟦 Клиент совершил 1 заказ: ${count_ones} шт.`
									);
									multiStringText.push(
										`🟥 Клиент совершил 2 заказа: ${count_twos} шт.`
									);
									multiStringText.push(
										`🟧 Клиент совершил 3 и более заказов: ${count_more} шт.`
									);
								}

								return multiStringText;
							},
						},
						backgroundColor: "#FFF",
						titleFontSize: 16,
						titleFontColor: "#3f51b5",
						bodyFontColor: "#666666",
						// bodyFontSize: 14,
						displayColors: false,
						borderColor: "#3f51b5",
						borderWidth: 2,
						bodySpacing: 5
					},
					responsive: true,
					maintainAspectRatio: false,
					title: {
						display: true
					}
				},

				optionsBar: {
					tooltips: {
						callbacks: {
							title: function(tooltipItem, data) {
								const beautiful_hour_labels = hour => {
									let hour_str = ("0" + `${hour}`).slice(-2);
									return hour_str + ":00";
								};

								let multiStringText = [];
								let deep = data.datasets[0].deep_chart;

								let t = data.datasets[0].timeCA[tooltipItem[0].index];
								let y = t.getFullYear();
								let d = t.getDate();
								let h = t.getHours();

								let monthString = new Intl.DateTimeFormat("ru-RU", {
									month: "short"
								}).format(t);
								let dayString = new Intl.DateTimeFormat("ru-RU", {
									weekday: "short"
								}).format(t);

								if (deep == "month") {
									multiStringText.push(`Дата: ${monthString} ${y}`);
								}
								if (deep == "day") {
									multiStringText.push(`Дата: ${d} ${monthString} ${y} (${dayString})`);
								}
								if (deep == "hour") {
									multiStringText.push(`Дата: ${d} ${monthString} ${y} (${dayString})`);
									multiStringText.push(`Время: ${beautiful_hour_labels(h)}`);
								}
								return multiStringText;
							},
							label: function(tooltipItem, data) {
								let multiStringText = [];
								let chartName = data.datasets[0].name_chart_line;
								multiStringText.push(``);

								if (chartName == "chartFrequency") {
									let count_ones = data.datasets[0].data[tooltipItem.index];
									let count_twos = data.datasets[1].data[tooltipItem.index];
									let count_more = data.datasets[2].data[tooltipItem.index];

									multiStringText.push(
										`🟦 Клиент совершил 1 заказ: ${count_ones} кл.`
									);
									multiStringText.push(
										`🟥 Клиент совершил 2 заказа: ${count_twos} кл.`
									);
									multiStringText.push(
										`🟧 Клиент совершил 3 и более заказов: ${count_more} кл.`
									);
								}

								return multiStringText;
							}
						},
						backgroundColor: "#FFF",
						titleFontSize: 16,
						titleFontColor: "#3f51b5",
						bodyFontColor: "#666666",
						// bodyFontSize: 14,
						displayColors: false,
						borderColor: "#3f51b5",
						borderWidth: 2,
						bodySpacing: 5
					},
					plugins: {
						labels: false
					},
					responsive: true,
					maintainAspectRatio: false,
					scales: {
						xAxes: [
							{
								stacked: true,
								categoryPercentage: 0.5,
								barPercentage: 1
							}
						],
						yAxes: [
							{
								stacked: true
							}
						]
					}
				},

				pie_options: {
					cutoutPercentage: 70,
					segmentShowStroke: true,
					segmentStrokeColor: "#fff",
					segmentStrokeWidth: 2,
					// percentageInnerCutout: 50,
					animationSteps: 100,
					animationEasing: "easeOutBounce",
					animateRotate: true,
					animateScale: false,
					responsive: true,
					maintainAspectRatio: false,
					showScale: true,

					legend: {
						display: false
					},
					tooltips: {
						callbacks: {},
						backgroundColor: "#FFF",
						titleFontSize: 16,
						titleFontColor: "#3f51b5",
						bodyFontColor: "#666666",
						//bodyFontSize: 14,
						displayColors: false,
						borderColor: "#3f51b5",
						borderWidth: 2,
						bodySpacing: 5
					}
				}
			};
		},

		mounted() {
			this.setSetting();
		},

		methods: {
			setSetting() {
				const byField = field => {
					return (a, b) => (a[field] > b[field] ? 1 : -1);
				};

				if (this.$store.state.user.role === 1) {
					this.$store.dispatch("points_get").then(res => {
						this.point_list = res.data.points.map(p => {
							let item = { id: p.id, name: p.name };
							if (p.status == 0) {
								return item;
							}
						});
						if (this.point_list.length != 0) {
							this.point_list.sort(byField("name"));
							this.point_id = this.point_list[0].id;
							this.point_select = this.point_list[0];
							this.setTimePreset();
						}
					});
                } else {
					this.point_id = this.$store.state.user.point_id;
					this.setTimePreset();
				}
			},

			setPoint() {
				this.point_id = this.point_select.id;
				this.getChartsGuestAnalytic();
			},

			getStrDate(date) {
				date = new Date(date);
				let y = date.getFullYear();
				let m = "0" + (date.getMonth() + 1);
				let d = "0" + date.getDate();
				return `${y}-${m.slice(-2)}-${d.slice(-2)}`;
			},
			setTimePreset() {
				const time_now = () => {
					return new Date(Date.now());
				};

				//console.log(this.time_preset_select);

				switch (this.time_preset_select) {
					case "Calendar":
						this.show_calendar = true;
						break;
					case "today":
						this.show_calendar = false;
						this.time_start = this.getStrDate(time_now());
						this.time_end = this.time_start;
						this.deep_select = "hour";
						break;
					case "yesterday":
						this.show_calendar = false;
						this.time_start = this.getStrDate(
							time_now().setDate(time_now().getDate() - 1)
						);
						this.time_end = this.time_start;
						this.deep_select = "hour";
						break;
					case "two days ago":
						this.show_calendar = false;
						this.time_start = this.getStrDate(
							time_now().setDate(time_now().getDate() - 2)
						);
						this.time_end = this.time_start;
						this.deep_select = "hour";
						break;
					case "Last week":
						this.show_calendar = false;
						this.time_start = this.getStrDate(
							time_now().setDate(time_now().getDate() - 7)
						);
						console.log(this.time_start)
						this.time_end = this.getStrDate(time_now());
						this.deep_select = "day";
						break;
					case "Last month":
						this.show_calendar = false;
						this.time_start = this.getStrDate(
							time_now().setDate(time_now().getDate() - 30)
						);
						this.time_end = this.getStrDate(time_now());
						this.deep_select = "day";
						break;
					case "Last 3 months":
						this.show_calendar = false;
						this.time_start = this.getStrDate(
							time_now().setDate(time_now().getDate() - 90)
						);
						this.time_end = this.getStrDate(time_now());
						this.deep_select = "month";
						break;
					case "Last 12 months":
						this.show_calendar = false;
						this.time_start = this.getStrDate(
							time_now().setDate(time_now().getDate() - 365)
						);
						this.time_end = this.getStrDate(time_now());
						this.deep_select = "month";
						break;
				}
				this.count_day =
					Math.floor(
						(new Date(this.time_end).getTime() -
							new Date(this.time_start).getTime()) /
							(1000 * 60 * 60 * 24)
					) + 1;
				this.checkPresetDeep();
				console.log(this.time_start, this.time_end);
				this.getChartsGuestAnalytic();
			},

			setTime() {
				this.dates_calendar.sort();

				if (this.dates_calendar.length == 2) {
					this.time_start = this.dates_calendar[0];
					this.time_end = this.dates_calendar[1];

					this.count_day =
						Math.floor(
							(new Date(this.time_end).getTime() -
								new Date(this.time_start).getTime()) /
								(1000 * 60 * 60 * 24)
						) + 1;

					this.checkPresetDeep();
					this.getChartsGuestAnalytic();
				}
			},

			resetData() {
				this.count_male = 0;
				this.count_female = 0;
				this.count_new = 0;
				this.count_old = 0;
				this.count_ones = 0;
				this.count_twos = 0;
				this.count_more = 0;
				this.proceeds_new = 0;
				this.proceeds_old = 0;
				this.delta_old_new = 0;
				this.sum_old_new = 0;
				this.time_list = [];
				this.count_new_list = [];
				this.count_old_list = [];
				this.count_ones_list = [];
				this.count_twos_list = [];
				this.count_more_list = [];
				this.count_male_list = [];
				this.count_female_list = [];
				this.proceeds_new_list = [];
				this.proceeds_old_list = [];
				this.chartCountNew = {};
				this.chartCountOld = {};
				this.chartFrequency = {};
				this.chartSex = {};
				this.chartProceedsNew = {};
				this.chartProceedsOld = {};
				this.chartProceeds = {};
				this.chartPieCountGender = {};
				this.chartPieProceeds = {};
				this.chartPieCounts = {};
				this.chartPieNewOld = {};
				this.chartCount = {};
				this.chartGenderMale = {};
				this.chartGenderFemale = {};
			},

			checkPresetDeep() {
				const getIndexDeep = d => {
					let i = false;
					this.deep_preset.forEach((item, index) => {
						if (item.deep == d) i = index;
					});
					return i;
				};

				let index_deep_hour = getIndexDeep("hour");
				let index_deep_day = getIndexDeep("day");

				if (this.count_day > 7) {
					if (this.deep_select == "hour") {
						this.deep_select = this.deep_preset[index_deep_day].deep;
					}
					this.deep_preset[index_deep_hour].customDisabled = true;
				} else {
					this.deep_preset[index_deep_hour].customDisabled = false;
				}
			},

			getChartsGuestAnalytic() {
				let beautiful_hour_labels = hour => {
					let hour_str = ("0" + `${hour}`).slice(-2);
					return hour_str + ":00";
				};

				let now_y = new Date(Date.now()).getFullYear();
				let timeCA = [];

				this.loading_show = true;
				this.$store
					.dispatch("get_guests_analytic", {
						point_id: this.point_id,
						time_start: this.time_start,
						time_end: this.time_end,
						deep: this.deep_select
					})
					.then(res => {
						this.resetData();
						this.getdate = res.data.data;

                        if (this.getdate) {
                            this.getdate.forEach((element, index) => {
                                let t = new Date(res.data.time[index]);
                                timeCA.push(t);
                                let y = t.getFullYear();
                                let m = t.getMonth() + 1;
                                let d = t.getDate();
                                let h = t.getHours();

                                let monthString = new Intl.DateTimeFormat("ru-RU", {
                                    month: "short"
                                }).format(t);
                                let dayString = new Intl.DateTimeFormat("ru-RU", {
                                    weekday: "short"
                                }).format(t);

                                if (this.deep_select == "month") {
                                    if (y < now_y) this.time_list.push(`${monthString} ${y}`);
                                    else this.time_list.push(`${monthString}`);
                                }
                                if (this.deep_select == "day") {
                                    if (y < now_y) this.time_list.push(`${d} ${monthString} ${y} ${dayString}`);
                                    else this.time_list.push(`${d} ${monthString} ${dayString}`);
                                }
                                if (this.deep_select == "hour") {
                                    if (h == 0) this.time_list.push(`${d} ${monthString} ${dayString}`);
                                    else this.time_list.push(`${beautiful_hour_labels(h)}`);
                                }

                                this.count_male += element.count_male;
                                this.count_female += element.count_female;
                                this.delta_old_new += element.count_old - element.count_new;
                                this.sum_old_new += element.count_old + element.count_new;
                                this.count_new += element.count_new;
                                this.count_old += element.count_old;
                                this.count_ones += element.count_ones;
                                this.count_twos += element.count_twos;
                                this.count_more += element.count_more;
                                this.proceeds_new += element.proceeds_new;
                                this.proceeds_old += element.proceeds_old;

                                this.count_new_list.push(element.count_new);
                                this.count_old_list.push(element.count_old);
                                this.count_ones_list.push(element.count_ones);
                                this.count_twos_list.push(element.count_twos);
                                this.count_more_list.push(element.count_more);
                                this.count_male_list.push(element.count_male);
                                this.count_female_list.push(element.count_female);
                                this.proceeds_new_list.push(element.proceeds_new);
                                this.proceeds_old_list.push(element.proceeds_old);
                            })
                        }

						this.delta_old_new = 1 - this.delta_old_new / this.sum_old_new;

						this.chartPieCountGender = {
							labels: ["Мужчины", "Женщины"],
							datasets: [
								{
									label: "",
									data: [this.count_male, this.count_female],
									backgroundColor: [
										"rgba(33, 150, 243, 1)",
										"rgba(233, 30, 99, 1)"
									],
									hoverOffset: 4
								}
							]
						};

						this.chartPieProceeds = {
							labels: ["Выручка от новых", "Выручка от постоянных"],
							datasets: [
								{
									label: "",
									data: [this.proceeds_new, this.proceeds_old],
									backgroundColor: [
										"rgba(255, 152, 0, 1)",
										"rgba(76, 175, 80, 1)"
									],
									hoverOffset: 4
								}
							]
						};

						this.chartPieNewOld = {
							labels: ["Новые клиенты", "Постоянные"],
							datasets: [
								{
									label: "",
									data: [this.count_new, this.count_old],
									backgroundColor: [
										"rgba(255, 152, 0, 1)",
										"rgba(76, 175, 80, 1)"
									],
									hoverOffset: 4
								}
							]
						};

						this.chartPieCounts = {
							labels: ["Единичные заказы", "Двойные", "Тройные и более"],
							datasets: [
								{
									label: "",
									data: [this.count_ones, this.count_twos, this.count_more],
									backgroundColor: ["#2196f3", "#dc3546", "#ff9800"],
									hoverOffset: 4
								}
							]
						};

						this.chartProceeds = {
							labels: this.time_list,
							datasets: [
								{
									name_chart_line: "chartProceeds",
									timeCA,
									label: "Выручка от новых",
									deep_chart: this.deep_select,
									data: this.proceeds_new_list,
									backgroundColor: "rgba(255, 152, 0, 0.3)",
									borderColor: "#ff9800"
								},
								{
									label: "Выручка от постоянных",
									data: this.proceeds_old_list,
									backgroundColor: "rgba(76, 175, 80, 0.3)",
									borderColor: "#4caf50"
								}
							]
						};

						this.chartProceedsNew = {
							labels: this.time_list,
							datasets: [
								{
									name_chart_line: "chartProceedsNew",
									timeCA,
									label: "Выручка от новых",
									deep_chart: this.deep_select,
									data: this.proceeds_new_list,
									backgroundColor: "rgba(255, 152, 0, 0.3)",
									borderColor: "#ff9800"
								}
							]
						};

						this.chartProceedsOld = {
							labels: this.time_list,
							datasets: [
								{
									name_chart_line: "chartProceedsOld",
									timeCA,
									label: "Выручка от постоянных",
									deep_chart: this.deep_select,
									data: this.proceeds_old_list,
									backgroundColor: "rgba(76, 175, 80, 0.3)",
									borderColor: "#4caf50"
								}
							]
						};

						this.chartSex = {
							labels: this.time_list,
							datasets: [
								{
									name_chart_line: "chartSex",
									timeCA,
									label: "Мужчины",
									deep_chart: this.deep_select,
									data: this.count_male_list,
									backgroundColor: "rgba(33, 150, 243, 0.3)",
									borderColor: "rgba(33, 150, 243, 1)"
								},
								{
									label: "Женщины",
									data: this.count_female_list,
									backgroundColor: "rgba(233, 30, 99, 0.3)",
									borderColor: "rgba(233, 30, 99, 1)"
								}
							]
						};

						this.chartGenderMale = {
							labels: this.time_list,
							datasets: [
								{
									name_chart_line: "chartGenderMale",
									timeCA,
									label: "Мужчины",
									deep_chart: this.deep_select,
									data: this.count_male_list,
									backgroundColor: "rgba(33, 150, 243, 0.3)",
									borderColor: "rgba(33, 150, 243, 1)"
								}
							]
						};

						this.chartGenderFemale = {
							labels: this.time_list,
							datasets: [
								{
									name_chart_line: "chartGenderFemale",
									timeCA,
									deep_chart: this.deep_select,
									label: "Женщины",
									data: this.count_female_list,
									backgroundColor: "rgba(233, 30, 99, 0.3)",
									borderColor: "rgba(233, 30, 99, 1)"
								}
							]
						};

						this.chartCountNew = {
							labels: this.time_list,
							datasets: [
								{
									name_chart_line: "chartCountNew",
									timeCA,
									label:"Заказы от новых",
									deep_chart: this.deep_select,
									data: this.count_new_list,
									backgroundColor: "rgba(255, 152, 0, 0.3)",
									borderColor: "#ff9800"
								}
							]
						};

						this.chartCountOld = {
							labels: this.time_list,
							datasets: [
								{
									name_chart_line: "chartCountOld",
									timeCA,
									label:"Заказы от постоянных",
									deep_chart: this.deep_select,
									data: this.count_old_list,
									backgroundColor: "rgba(76, 175, 80, 0.3)",
									borderColor: "#4caf50"
								}
							]
						};

						this.chartCount = {
							labels: this.time_list,
							datasets: [
								{
									name_chart_line: "chartCount",
									timeCA,
									label:"Заказы от постоянных",
									deep_chart: this.deep_select,
									data: this.count_old_list,
									backgroundColor: "rgba(76, 175, 80, 0.3)",
									borderColor: "#4caf50"
								},
								{
									label:"Заказы от новых",
									data: this.count_new_list,
									backgroundColor: "rgba(255, 152, 0, 0.3)",
									borderColor: "#ff9800"
								}
							]
						};

						this.chartFrequency = {
							labels: this.time_list,
							datasets: [
								{
									name_chart_line: "chartFrequency",
									timeCA,
									deep_chart: this.deep_select,
									label: "Один раз",
									backgroundColor: "#2196f3",
									data: this.count_ones_list
								},
								{
									label: "Два раза",
									backgroundColor: "#dc3546",
									data: this.count_twos_list
								},
								{
									label: "Три и более",
									backgroundColor: "#ff9800",
									data: this.count_more_list
								}
							]
						};

						this.loading_show = false;
					});
			}
		}
	};
</script>

<style scoped>
	@media (max-width: 960px) {

		.card-center {
			justify-content: center;
		}
	}

	.pie-wrapper {
		position: relative;
	}
	.center-wrapper {
		top: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
	}
	.piechartZ {
		position: relative;
		z-index: 2 !important;
		background-color: transparent !important;
	}
	.card-pie {
		width: 260px !important;
		height: 230px !important;
	}
</style>
